import { Skeleton } from './skeleton';
interface SkeletonTableProps {
  columns?: number;
  rows?: number;
}
export function SkeletonTable({
  columns = 3,
  rows = 5
}: SkeletonTableProps = {}) {
  return <div className="w-full overflow-x-auto" data-sentry-component="SkeletonTable" data-sentry-source-file="skeleton-table.tsx">
      <div className="inline-block min-w-full">
        <div className="border rounded-lg shadow-sm">
          <div className={`grid grid-cols-${columns} bg-muted/50`}>
            {[...Array(columns)].map((_, i) => <div key={i} className="p-2 border-r border-b">
                <Skeleton className="h-4 w-full" />
              </div>)}
          </div>
          {[...Array(rows)].map((_, rowIndex) => <div key={rowIndex} className={`grid grid-cols-${columns}`}>
              {[...Array(columns)].map((_, colIndex) => <div key={colIndex} className="p-2 border-r border-b">
                  <Skeleton className="h-4 w-full" />
                </div>)}
            </div>)}
        </div>
      </div>
    </div>;
}