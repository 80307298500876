import { ArrowDownIcon, ArrowUpIcon } from '@radix-ui/react-icons';
import React from 'react';
import { cn } from '../../lib';
import { Button, Input } from '../inputs';
function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={cn('animate-pulse rounded-md bg-primary/10', className)} {...props} data-sentry-component="Skeleton" data-sentry-source-file="skeleton.tsx" />;
}
function SkeletonCardBuilder() {
  return <div className="w-full mx-auto p-6 bg-white dark:bg-slate-800 shadow-lg rounded-lg  border border-gray-200" data-sentry-component="SkeletonCardBuilder" data-sentry-source-file="skeleton.tsx">
      <div className=" gap-3  flex flex-col">
        <Skeleton className="h-4 w-3/12" data-sentry-element="Skeleton" data-sentry-source-file="skeleton.tsx" />
        <Skeleton className="h-4 w-2/5" data-sentry-element="Skeleton" data-sentry-source-file="skeleton.tsx" />
        <Skeleton className="h-2 w-2/3" data-sentry-element="Skeleton" data-sentry-source-file="skeleton.tsx" />
        <div className="space-y-2">
          <Skeleton className="h-4 w-2/12" data-sentry-element="Skeleton" data-sentry-source-file="skeleton.tsx" />
          <textarea disabled className="border border-slate-200 rounded-md p-2 w-full mb-4 text-sm" />
        </div>
      </div>
    </div>;
}
function SkeletonCardBuilderVisualize() {
  return <div className="w-full mx-auto p-4 bg-white dark:bg-slate-800 shadow-lg rounded-lg max-w-xl" onClick={() => {}} data-sentry-component="SkeletonCardBuilderVisualize" data-sentry-source-file="skeleton.tsx">
      <div className="flex  bottom-0 pb-2">
        <div className="flex-1 flex justify-between">
          <Button variant="outline" className="bg-indigo-100 rounded-full border w-auto text-indigo-500 font-bold" data-sentry-element="Button" data-sentry-source-file="skeleton.tsx">
            <Skeleton className="h-4 w-2" data-sentry-element="Skeleton" data-sentry-source-file="skeleton.tsx" />
          </Button>
          <div>
            <Button variant="outline" className=" rounded-r-none border px-3" disabled={true} onClick={() => {}} data-sentry-element="Button" data-sentry-source-file="skeleton.tsx">
              <ArrowUpIcon className="h-4 w-4 text-slate-500" data-sentry-element="ArrowUpIcon" data-sentry-source-file="skeleton.tsx" />
            </Button>
            <Button variant="outline" className=" border  rounded-l-none px-3" disabled={true} onClick={() => {}} data-sentry-element="Button" data-sentry-source-file="skeleton.tsx">
              <ArrowDownIcon className="h-4 w-4 text-slate-500" data-sentry-element="ArrowDownIcon" data-sentry-source-file="skeleton.tsx" />
            </Button>
          </div>
        </div>
      </div>
      <Skeleton className="h-4 w-3/12 mb-2" data-sentry-element="Skeleton" data-sentry-source-file="skeleton.tsx" />
      <Input type="text" disabled name="name" className="border border-slate-200 rounded-md p-2 w-full mb-2" data-sentry-element="Input" data-sentry-source-file="skeleton.tsx" />
      <Skeleton className="h-4 w-3/12 mb-2" data-sentry-element="Skeleton" data-sentry-source-file="skeleton.tsx" />
      <div className="flex-1 flex justify-between items-center">
        <Input type="text" name="description" disabled className="border border-slate-200 rounded-md p-2 w-full me-3 mb-2" data-sentry-element="Input" data-sentry-source-file="skeleton.tsx" />
      </div>
      <Skeleton className="h-4 w-3/12 mb-2" data-sentry-element="Skeleton" data-sentry-source-file="skeleton.tsx" />
      <Input type="text" name="textarea" disabled className="border border-slate-200 rounded-md p-2 w-full mb-2" data-sentry-element="Input" data-sentry-source-file="skeleton.tsx" />
      <Skeleton className="h-4 w-3/12 mb-2" data-sentry-element="Skeleton" data-sentry-source-file="skeleton.tsx" />
      <Input type="text" name="cta" disabled className="border border-slate-200 rounded-md p-2 w-full mb-4" data-sentry-element="Input" data-sentry-source-file="skeleton.tsx" />
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <Skeleton className="h-4 w-3/12 mb-2" data-sentry-element="Skeleton" data-sentry-source-file="skeleton.tsx" />
          <div>
            <Skeleton className="h-4 w-40" data-sentry-element="Skeleton" data-sentry-source-file="skeleton.tsx" />
          </div>
        </div>
        <Button variant={'outline'} onClick={() => {}} data-sentry-element="Button" data-sentry-source-file="skeleton.tsx">
          <Skeleton className="h-4 w-40" data-sentry-element="Skeleton" data-sentry-source-file="skeleton.tsx" />
        </Button>
      </div>
    </div>;
}
export { Skeleton, SkeletonCardBuilder, SkeletonCardBuilderVisualize };