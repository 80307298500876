'use client';

import { Toaster as Sonner, toast, useSonner } from 'sonner';
type ToasterProps = React.ComponentProps<typeof Sonner>;
const Toaster = ({
  ...props
}: ToasterProps) => {
  return <Sonner theme={props.theme} className="toaster group" toastOptions={{
    classNames: {
      toast: 'group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg',
      description: 'group-[.toast]:text-muted-foreground',
      actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
      cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground'
    }
  }} {...props} data-sentry-element="Sonner" data-sentry-component="Toaster" data-sentry-source-file="sonner.tsx" />;
};
export { toast, Toaster, useSonner };